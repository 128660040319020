/*=============================================
=                Variables                    =
=============================================*/

$default-font-family: var(--font-base);
$monospace-font-family: var(--font-mono);

$performance-panel-width: 388px;

$sidebar-width: 468px;
$overlay-side-margin: 16px;

$breakpoint-tablet: 768px;
$breakpoint-standard: 1024px;
$breakpoint-large: 1440px;
$breakpoint-xlarge: 2048px;

$max-width-body: 2014px;
$max-width-content: 1408px;

$columns-mobile: 4;
$columns-desktop: 12;
