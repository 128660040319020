/*=============================================
=                Typography                   =
=============================================*/

// Fonts & Font Weights

@mixin heading(
  $font-size: 24,
  $letter-spacing: inherit,
  $font-weight: 400,
  $line-height: 1.25
) {
  font-family: $default-font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;

  @if $letter-spacing != 0 {
    letter-spacing: $letter-spacing;
  }
}

@mixin body(
  $font-size: 24,
  $letter-spacing: 0,
  $font-weight: 400,
  $line-height: 1.5
) {
  font-family: $default-font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;

  @if $letter-spacing != 0 {
    letter-spacing: $letter-spacing;
  }
}

@mixin mono(
  $font-size: 24,
  $letter-spacing: 0,
  $font-weight: 400,
  $line-height: 1.5
) {
  font-family: $monospace-font-family;
  line-height: $line-height;
  font-size: $font-size;
  font-weight: $font-weight;
  text-transform: uppercase;
  @if $letter-spacing != 0 {
    letter-spacing: $letter-spacing;
  }
}

// Headings

// new code
@mixin digit() {
  @include mono(300px, -0.02em, 400, 0.9);
  color: var(--grey2);
  margin-left: -16px;

  @include for-mobile-screens {
    font-size: 200px;
    margin-left: -14px;
  }
}

@mixin h1() {
  @include mono(56px, -0.02em, 400, 1);
  color: var(--zest3);

  @include for-mobile-screens {
    font-size: 36px;
  }
}

@mixin h2() {
  @include mono(18px, -0.02em, 700, 1.3);
  text-transform: uppercase;

  @include for-mobile-screens {
    font-size: 12px;
    line-height: 1.15;
  }
}

@mixin h3() {
  @include heading(44px, -0.04em, 400, 1.2);

  @include for-mobile-screens {
    font-size: 28px;
    line-height: 1.15;
  }
}

@mixin h4() {
  @include heading(14px, 0, 400, 1.22);
  font-family: $monospace-font-family;

  @include for-mobile-screens {
    font-size: 14px;
    line-height: 1.375;
  }
}

@mixin blockquote() {
  @include heading(22px, -0.04em, 400, 1.2);
  font-style: italic;

  @include for-mobile-screens {
    font-size: 20px;
    line-height: 1.15;
  }
}

@mixin body1() {
  @include body(20px, -0.02em, 400, 1.3);

  @include for-mobile-screens {
    font-size: 16px;
    line-height: 1.375;
  }
}

@mixin body2() {
  @include body(16px, -0.02em, 400, 1.375);

  @include for-mobile-screens {
    font-size: 14px;
    line-height: 1.42;
  }
}

@mixin body3() {
  @include body(14px, -0.02em, 400, 1.5);

  @include for-mobile-screens {
    font-size: 12px;
    line-height: 1.66;
  }
}

@mixin label() {
  @include mono(14px, 0.01em, 400, 1.23);
  text-transform: uppercase;

  @include for-mobile-screens {
    font-size: 12px;
    line-height: 1.33;
  }
}


@mixin button() {
  @include body(13px, 0.01em, 700, 1.2);
  text-transform: uppercase;
}

@mixin nav-link() {
  @include body(16px, -0.02em, 400, 1.375);

  @include for-tablet-landscape {
    font-size: 22px;
    line-height: 1.45;
  }
}

// old code
@mixin h-100() {
  @include heading(100px, -0.04em, 400, 1.1);
}

@mixin h-64() {
  @include heading(64px, -0.04em, 400, 1.2);
}

@mixin h-48() {
  @include heading(48px, -0.04em, 400, 1.2);
}

@mixin h-32() {
  @include heading(32px, -0.04em, 400, 1.2);
}

@mixin h-24() {
  @include heading(24px, -0.02em, 400, 1.3);
}

@mixin h-18() {
  @include heading(18px, -0.02em, 400, 1.3);
}

// TODO: mobile headers

// Text
@mixin p-32($font-weight: 400) {
  @include body(32px, -0.02em, $font-weight, 1.5);
}
@mixin p-24($font-weight: 400) {
  @include body(24px, -0.02em, $font-weight, 1.5);
}
@mixin p-18($font-weight: 400) {
  @include body(18px, -0.02em, $font-weight, 1.5);
}
@mixin p-16($font-weight: 400) {
  @include body(16px, -0.02em, $font-weight, 1.5);
}
@mixin p-14($font-weight: 400) {
  @include body(14px, -0.02em, $font-weight, 1.5);
}
@mixin p-12($font-weight: 400) {
  @include body(12px, -0.02em, $font-weight, 1.5);
}

@mixin m-16($font-weight: 400) {
  @include mono(16px, -0.02em, $font-weight, 1.5);
}
@mixin m-48($font-weight: 400) {
  @include mono(48px, -0.04em, $font-weight, 1.2);
}
