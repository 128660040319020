@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.editorial {
  display: flex;
  flex-direction: column;
  gap: 42px;
  align-items: flex-start;

  &.centerAlign {
    align-items: center
  }
}

.portableGrid {
  grid-template-columns: repeat(6, 1fr);
}
.portableOverrides {
  > p:first-child {
    margin-top: 0;
  }
}

.sectionWrap {
  padding-top: var(--sectionPaddingLarge);
  padding-bottom: var(--sectionPaddingLarge); 
  
  &.large {
    padding-top: var(--sectionPaddingLarge);
    padding-bottom: var(--sectionPaddingLarge); 
  }

  &.medium {
    padding-top: var(--sectionPaddingMedium);
    padding-bottom: var(--sectionPaddingMedium); 
  }

  &.small {
    padding-top: var(--sectionPaddingSmall);
    padding-bottom: var(--sectionPaddingSmall); 
  }

  &.none {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.mediaWrap {
  width: 100%;
  height: 100%;
}

.media {
  aspect-ratio: 4/3;
  height: auto;
  max-height: 100%;
  width: 100%;
}

.quote {
  &::before {
    content: open-quote;
  }
  &::after {
    content: close-quote;
  }
}

.twoMedia,
.oneMedia,
.customerStories,
.cardAndMediaWrap {
  padding-top: 72px;
}

.customerStoriesWrap {
  padding-top: 72px;
  > ul {
    display: flex;
    gap: var(--padding);

    @include for-mobile-screens {
      height: 428px;
    }
  }
}

.slide {
  position: relative;
  flex: 0 0 auto;
  width: 70%;
  aspect-ratio: 4/3;
  overflow: hidden;

  @include for-mobile-screens {
    width: 95%;
  }
}

.carouselCard {
  width: 100%;
  height: 100%;
}


.twoMediaWrap {
  max-height: 561px;
}

.oneMediaWrap {
  max-height: 730px;
}

.oneMediaContent {
  aspect-ratio: 16/9;
  max-height: 100%;
  width: 100%;
  height: auto;

  @include for-mobile-screens {
    aspect-ratio: 9/16;
    height: 730px;
  }
}

.oneMediaControls {
  @include for-mobile-screens {
    aspect-ratio: 16/9;
  }
}

.columnPadding {
  @include for-mobile-screens {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 42px;
  align-items: flex-start;
}

.listItem {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;

  & > :first-child {
    text-transform: uppercase;
  }
}

.mobileOnly {
  @media (min-width: $breakpoint-tablet) {
    display: none;
  }
}

.desktopOnly {
  @media (max-width: $breakpoint-tablet) {
    display: none;
  }
}

.customerStories,
.customerStoriesCarousel {
  @include for-mobile-screens {
    padding-top: 24px;
  }
}

.storyCard {
  position: relative;
  flex: 0 0 50%;
  border-radius: 24px;
  overflow: hidden;

  @media (max-width: $standard-screen-min) {
    padding: 16px 16px 24px;
    border-radius: 12px;
    background-color: var(--grey2);

    + .storyCard {
      margin-top: 8px;
    }
  }
}

.storyContentWrap {
  position: absolute;
  left: 16px;
  bottom: 16px;
  background-color: var(--white);
  border-radius: 16px;
  overflow: hidden;

  @media (max-width: $standard-screen-min) {
    position: relative;
    max-width: unset;
    left: unset;
    bottom: unset;
    border-radius: unset;
    background-color: unset;
  }
}

.storyContent {
  padding: 12px;
  display: inline-block;

  @media (max-width: $standard-screen-min) {
    padding: 24px 0 0 0;
  }

  > div {
    position: relative;
  }
}

.storyImage {
  aspect-ratio: 4/3;
  height: auto;
  max-height: 100%;
  width: 100%;
  overflow: hidden;

  @media (max-width: $standard-screen-min) {
    border-radius: 8px;
    overflow: hidden;
  }
}

.storyCustomer {
  margin-top: 4px;

  @media (max-width: $standard-screen-min) {
    display: none;
  }

  > span {
    position: absolute;
  }
}

.storyHover {
  position: absolute;
  top: 0;
  width: 320px;

  @media (max-width: $standard-screen-min) {
    position: relative;
    width: unset;
    margin-top: 24px;
  }
}

.storyBlurb {
  margin-bottom: 24px;

  @media (max-width: $standard-screen-min) {
    margin-top: 24px;
  }
}

.storyLinkLabel {
  font-weight: 700;
}

.bigGallery {
  padding-top: 59px;

  @include for-mobile-screens {
    padding-top: 24px;
  }
}

.autostoreCard {
  background-color: var(--grey2);
  border-radius: 16px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;

  min-height: 210px;
  height: 100%;

  @include for-mobile-screens {
    min-height: 0px;
  }

  &:hover,
  &:active {
    cursor: pointer;
    > div > button {
      color: var(--teal);
    }
  }
}

.cardAndMediaWrap {
  @include for-mobile-screens {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
  }
}
