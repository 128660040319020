@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.sectionWrap {
  background-color: white;
  padding-top: var(--sectionPaddingMedium);
  padding-bottom: var(--sectionPaddingLarge);
}

.columnPadding {
  @include for-mobile-screens {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.bottomColumnsPadding {
  padding-top: 42px;
  @include for-mobile-screens {
    padding-top: 0;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include for-mobile-screens {
    margin-top: 24px;
  }
}

.priceOverview {
  display: flex;
  flex-direction: column;
  gap: 48px;
  background-color: var(--grey2);
  padding: 32px 48px 48px 48px;
  border-radius: 16px;



  @include for-mobile-screens {
    padding: 24px 16px;
    border-radius: 12px;
  }

  &.blurred {
    filter: blur(13px);
    -webkit-filter: blur(13px);
  }
}

.chartWrapper {
  height: 256px;
}

.numbers {
  display: flex;
  flex-direction: row;
  gap: 64px;

  @include for-mobile-screens {
    flex-direction: column;
    gap: 32px;
  }
}

.numbersPair {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.annualSavings {
  @include h1;
  color: var(--navy);
}

.otherSavings {
  color: var(--grey5);
  @include h4;
}

.savingsColumn {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include for-mobile-screens {
    gap: 24px;
  }
}

.emailInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--grey2);
  border-radius: 16px;
  padding: 16px;
  gap: 24px;
  justify-content: space-between;

  @include for-mobile-screens {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    justify-content: baseline;
  }
}

.inputText {
  width: 262px;
}

.emailInput {
  max-width: 432px;
}

.formDisabled {
  pointer-events: none;
}

.link {
  text-decoration: underline;
  text-transform: none;
  cursor: pointer;

  &:hover,
  :focus {
    color: var(--grey5);
  }
}

.errorBlurb {
  padding: 16px;
  background-color: var(--zest);
  border-radius: 4px;
}
