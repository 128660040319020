@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrap {
  position: relative;
  padding-left: var(--sectionPaddingSmall);
  padding-right: var(--sectionPaddingSmall);
  // background-color: var(--white);
}

.bg-gray {
  background-color: var(--grey2);
}
