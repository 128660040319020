@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrap {
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  z-index: 1; // safari border-radius fix

  > img,
  video {
    border-radius: 24px;
    position: absolute; // contains it to its parent size

    @include for-mobile-screens {
      border-radius: 12px;
    }
  }

  @include for-mobile-screens {
    border-radius: 12px;
  }
}
