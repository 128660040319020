$large-screen-min: 1440px;
$standard-screen-min: 1024px;
$ipad-min: 768px;

// This should have: (min-width: $ipad-min) and
// but it's weird to have the items grow bigger at a smaller size again
@mixin for-mobile-screens {
  @media only screen and (max-width: $ipad-min) {
    @content;
  }
}

@mixin for-tablet-landscape {
  @media only screen and (max-width: $standard-screen-min) {
    @content;
  }
}
@mixin for-standard-screens {
  @media only screen and (min-width: $standard-screen-min) and (max-width: $large-screen-min) {
    @content;
  }
}
@mixin for-large-screens {
  @media only screen and (min-width: $large-screen-min) {
    @content;
  }
}
@mixin for-small-screens {
  @media only screen and (min-width: $ipad-min) and (max-width: $large-screen-min) {
    @content;
  }
}
