@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrap {
  > ul {
    display: flex;
    gap: var(--padding);
  }
}

.slide {
  position: relative;
  flex: 0 0 auto;
  width: 70%;
  aspect-ratio: 4/3;
  overflow: hidden;

  @include for-mobile-screens {
    width: 95%;
  }
}

.media {
  width: 100%;
  height: 100%;
}
