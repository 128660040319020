@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.link {
  text-decoration: underline;
  text-transform: none;

  transition: all 0.2s ease-in;

  &:hover {
    text-decoration-color: var(--teal);
  }
}

.title {
  margin-top: 72px;

  @include for-mobile-screens {
    margin-top: 56px;
  }
}

.title:first-child,
.text:first-child,
.label:first-child {
  margin-top: 0;
}

.uppercase {
  text-transform: uppercase;
}

.label,
.text {
  margin-top: 42px;

  @include for-mobile-screens {
    margin-top: 24px;
  }
}

.list > * {
  margin-top: 12px;
  color: var(--grey5);
}

.list {
  list-style-position: inside;
  //list-style: disc;
}

.decimal {
  list-style-type: decimal;
}

.disc {
  list-style-type: disc;
}

.bold {
  font-weight: bold;
}

.InlineImage {
  > img {
    position: relative !important;
  }
}